<template>
  <v-main class="container-prosker title_highlight_color">
    <v-row class="position-relative">
      <img src="@/assets/images/page-img/Vector_16.png" class="position-absolute top-0 right-0">
      <v-col md="6" cols="12" class="overflow-visible">
        <v-row class="mt-10 pb-10 pt-2 px-5">
          <v-container class="position-relative">
            <h1 class="text-left text_contrast_to_background--text" >{{ $t("howCanWeHelp") }}</h1>
            <h5 class="text-left text_contrast_to_background--text mt-5">{{ $t("howCanHelpText") }}</h5>
            <v-col cols="12">
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-icon>
                  <v-icon v-text="item.icon" class="primary--text"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span class="text_contrast_to_background--text">{{ item.text }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :href="whatsappNumber" target="_blank">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-whatsapp</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span class="text_contrast_to_background--text">PROSK</span>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" class="text-left mt-15 contact-items">
              <v-btn large icon :href="facebookUrl" target="_blank" class="me-2">
                <v-icon large color="white">mdi-facebook</v-icon>
              </v-btn>
              <v-btn large icon href="https://www.linkedin.com/company/prosk/" target="_blank" class="me-2">
                <v-icon large color="white">mdi-linkedin</v-icon>
              </v-btn>
              <v-btn large icon :href="instagramUrl" target="_blank" class="me-2">
                <v-icon large color="white">mdi-instagram</v-icon>
              </v-btn>
            </v-col>
            <img src="@/assets/images/page-img/Vector_17.png" class="vector">
          </v-container>
        </v-row>
      </v-col>
      <v-col md="6" cols="12 pr-5 pb-5 pl-5 p-md-0" >
        <v-card class="mt-10 pb-10 pt-5 px-5 border-radius-medium" :class="$vuetify.breakpoint.mobile ? 'w-100' : 'w-90'">
          <h1 class="text-left secondary--text">{{ $t("contactUs") }}</h1>
          <v-container>
                <custom-text-field
                :customLabel="$t('name')"
              required
              color="#59D79D"
              :hide-details="errorName.length === 0"
              :error-count="errorName.length"
              :error-messages="errorName"
              v-model="name"
            />
            <custom-text-field
            :customLabel="$t('lastName')"
              required
              color="#59D79D"
              :hide-details="errorLastName.length === 0"
              :error-count="errorLastName.length"
              :error-messages="errorLastName"
              v-model="lastName"
            />
            <custom-text-field
            :customLabel="$t('email')"
              required
              color="#59D79D"
              :hide-details="errorLastName.length === 0"
              :error-count="errorLastName.length"
              :error-messages="errorLastName"
              v-model="email"
            />
            <v-textarea
              class="mt-8 border-radius-small"
              solo
              :label="$t('message')"
              required
              color="#59D79D"
              :hide-details="errorMessage.length === 0"
              :error-count="errorMessage.length"
              :error-messages="errorMessage"
              v-model="message"
              :style="{
             border: '1px solid #212833',
            }"
            ></v-textarea>
            <v-col cols="12">
              <general-button :action="onSubmit" :loading="loading" :message="$t('send')" secondary cssClasses="w-100"/>
            </v-col>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import userDataMixin from '@/mixins/userDataMixin';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import CustomTextField from '@/components/socialvue/customTextField/CustomTextField.vue';

export default {
  name: 'ContactPage',
  components: { GeneralButton, CustomTextField },
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      items: [
        {
          icon: 'mdi-map-marker',
          text: process.env.VUE_APP_LOCATION.includes('br') ? 'São Paulo, Brasil' : 'Av. Rivera 2248, Montevideo - Uruguay'
        },
        {
          icon: 'mdi-email-outline',
          text: process.env.VUE_APP_LOCATION.includes('br') ? 'atendimento@prosk.com.br' : 'info@prosk.com.uy'
        }
      ],
      name: null,
      errorName: [],
      lastName: null,
      errorLastName: [],
      email: null,
      errorEmail: [],
      message: null,
      errorMessage: [],
      loading: false,
      facebookUrl: process.env.VUE_APP_LOCATION.includes('br') ? 'https://www.facebook.com/ProskBrasil' : 'https://www.facebook.com/prosk.com.uy/',
      instagramUrl: process.env.VUE_APP_LOCATION.includes('br') ? 'https://www.instagram.com/prosk.br' : 'https://instagram.com/prosk.uy?igshid=YmMyMTA2M2Y=',
      whatsappNumber: process.env.VUE_APP_LOCATION.includes('br') ? 'https://api.whatsapp.com/send?phone=5511949170322' : 'https://api.whatsapp.com/send?phone=59898702882'
    };
  },
  created () {
    if (window.location.pathname !== '/perfil') {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  },
  methods: {
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    resetErrors () {
      this.errorName = [];
      this.errorLastName = [];
      this.errorEmail = [];
      this.errorMessage = [];
    },
    reset () {
      this.name = null;
      this.lastName = null;
      this.email = null;
      this.message = null;
    },
    onSubmit () {
      this.resetErrors();
      if (
        this.name &&
        this.lastName &&
        this.email &&
        this.message &&
        this.validEmail(this.email)
      ) {
        this.loading = true;
        const data = {
          name: this.name,
          last_name: this.lastName,
          message: this.message,
          email: this.email
        };
        const url = `${process.env.VUE_APP_BASE_URL}/api/contact-page`;
        axios
          .post(url, data)
          .then(() => {
            this.reset();
            this.$swal.fire({
              title: this.$t('awesome'),
              text: this.$t('thankContactingUsWillReplySoon'),
              confirmButtonColor: '#19AD93'
            });
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
      if (!this.email) {
        this.errorEmail.push(this.$t('emailRequired'));
      }
      if (!this.validEmail(this.email)) {
        this.errorEmail.push(this.$t('enterValidEmail'));
      }
      if (!this.name) {
        this.errorName.push(this.$t('fieldNameRequired'));
      }
      if (!this.lastName) {
        this.errorLastName.push(this.$t('fieldLastNameRequired'));
      }
      if (!this.message) {
        this.errorMessage.push(this.$t('fieldMessageRequired'));
      }
    },
    sendDataLayerInfo () {
      this.trackUserData('load_content', {
        pageType: 'my_account',
        pageSubType: 'ayuda'
      });
    }
  },
  mounted () {
    this.sendDataLayerInfo();
  }
};
</script>
<style scoped>
.vector{
  position: absolute;
  bottom:-260px;
  right: -180px;
}

.w-90 {
  width: 90%;
}
.contact-items {
  z-index: 10;
}
</style>
